<template>
  <div>
    <!-- Modal to edit Guest Info -->
    <guest-editing v-bind:guest="guestUser" v-on:guest-updated="getResidents()" />

    <b-row v-if="listResidents.length>0">
      <b-col cols="12">
        <!-- Show the table -->
        <div style="overflow-y:hidden;">
          <div class="list">
            <row-residents
              v-for="item in listResidents"
              v-bind:resident="item"
              v-bind:key="item.id"
              v-on:listChange="listChange"
              v-on:editGuest="editGuest"
              v-bind:timeday="{isAfterBreakfast: isAfterBreakfast(), isAfterLunch: isAfterLunch(), isAfterDinner: isAfterDinner() }"
            ></row-residents>
          </div>
        </div>

        <b-row class="text-left">
          <b-button @click="addGuest()" class="text-left mt-3 ml-3" variant="info">Add Guest</b-button>
        </b-row>

        <!-- Show list info entered -->
        <b-row class="text-center mt-3" v-if="!gettingLastEntryCurrentUser">
          <div class="ml-3" v-html="lastEntryCurrentUser" style="font-size: 0.9em;"></div>
        </b-row>
        <b-row class="text-center mt-3" v-else>
          <font-awesome-icon icon="spinner" :style="{ color: 'gray' }" spin />
        </b-row>
      </b-col>
    </b-row>
    <div v-else>
      <font-awesome-icon icon="sync-alt" spin size="3x" :style="{ color: 'blue' }" />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import RowResidents from "./RowResidents.vue";
import GuestEditing from "./GuestEditing.vue";

export default {
  props: {},
  data: function() {
    return {
      listResidents: [],
      guestUser: {
        name: "",
        diet: "",
        id: ""
      },
      lastEntryMissing: [],
      gettingLastEntryCurrentUser: false,
      count: 0
    };
  },
  computed: {
    currentDay() {
      return this.$store.state.currentDay;
    },
    infoUser() {
      return this.$store.state.userInfo;
    },
    reloadData() {
      return this.$store.state.reloadData;
    },
    lastEntryCurrentUser() {
      var stDate = this.lastEntryMissing.DateMissing;
      return moment(stDate).calendar(null, {
        sameDay:
          "[<span style='color: red'>Your meal account is missing TODAY's info!</span>]",
        nextDay:
          "[<span style='color: red'>Your meal account is missing Tomorrow's info!</span>]",
        nextWeek:
          "[<span style='color: green'>You are all set until ]dddd[</span>]",
        lastDay: "[Yesterday]",
        lastWeek: "[Last] dddd",
        sameElse:
          "[<span style='color: green'>Good! You are all set until ]MMMM D[</span>]"
      });
    }
  },
  components: {
    RowResidents,
    GuestEditing
  },
  mounted() {
    this.getResidents();
    this.updateLastDayMissing();
  },
  watch: {
    currentDay: function(nv, ov) {
      this.getResidents();
      this.updateLastDayMissing();
    },
    reloadData: function(nv, ov) {
      if (this.reloadData) {
        this.getResidents();
        this.updateLastDayMissing();
        this.$store.commit("reloadData", false);
      }
    }
  },
  methods: {
    getResidents() {
      this.count++;
      var url = "getOnlyResidents.php";
      var st = moment(this.currentDay).format("YYYY-MM-DD");
      var data = { date: st };
      this.listResidents = [];
      this.$http.post(url, data, { emulateJSON: true }).then(
        res => {
          this.listResidents = res.body.People;
          this.count = 0;
        },
        err => {
          this.listResidents = [];
          if (this.count < 10) {
            this.$bvToast.toast(`Trying to reconect...`, {
              title: "Connecction lost",
              variant: "info",
              toaster: "b-toaster-top-center",
              autoHideDelay: 2500,
              appendToast: false
            });
            setTimeout(this.getResidents, 3000);
            setTimeout(this.updateLastDayMissing, 3000);
          } else {
            this.$bvToast.toast(
              `Sorry, check your Internet connection and try again...`,
              {
                title: "Connecction lost",
                variant: "info",
                toaster: "b-toaster-top-center",
                noAutoHide: true,
                appendToast: false
              }
            );
          }
        }
      );
    },
    updateLastDayMissing() {
      var url = "getLastDayMissingId.php";
      var id = this.infoUser.id;
      var data = { date: moment(this.currentDay).format("YYYY-MM-DD"), id: id };
      this.gettingLastEntryCurrentUser = true;
      this.$http.post(url, data, { emulateJSON: true }).then(
        res => {
          this.lastEntryMissing = res.body.Missing;
          this.gettingLastEntryCurrentUser = false;
        },
        err => {}
      );
    },

    isAfterBreakfast() {
      var tb = moment(this.currentDay)
        .hour(8)
        .minute(0);
      return moment().isAfter(tb);
    },
    isAfterLunch() {
      var tl = moment(this.currentDay)
        .hour(12)
        .minute(0);
      return moment().isAfter(tl);
    },
    isAfterDinner() {
      var td = moment(this.currentDay)
        .hour(18)
        .minute(0);
      return moment().isAfter(td);
    },
    listChange(obj) {
      var resident = obj.resident;
      var meal = obj.meal;
      var status = obj.type;
      var isGuest = resident.Guest == "1";
      var isCurrentUser = resident.CurrentUser == "1";
      var isAdmin = (this.infoUser.status & 8) > 0;
      if (isGuest || isCurrentUser || isAdmin) {
        var index = this.listResidents.findIndex(x => x.id == resident.id);

        if (this.listResidents[index][meal] == status) {
          this.listResidents[index][meal] = "";
        } else {
          this.listResidents[index][meal] = status;
        }
        var guest = this.listResidents[index].Guest;
        this.updateMeals(resident.id, meal, status, guest);
      } else {
      }
    },
    updateMeals(id, meal, status, guest) {
      var url = "updateMeals.php";
      var date = moment(this.currentDay).format("YYYY-MM-DD");
      var data = {
        date: date,
        id: id,
        meal: meal,
        status: status,
        guest: guest
      };
      if (meal == "Dinner") {
        this.gettingLastEntryCurrentUser = true;
      }
      this.$http.post(url, data, { emulateJSON: true }).then(
        res => {
          if (meal == "Dinner") {
            this.updateLastDayMissing();
          }
        },
        err => {}
      );
    },
    editGuest(guest) {
      this.guestUser.name = guest.UserName;
      this.guestUser.diet = guest.Diet;
      this.guestUser.id = guest.id;
      this.$bvModal.show("modal-guest");
    },
    addGuest() {
      this.guestUser.name = "";
      this.guestUser.diet = "";
      this.guestUser.id = "";
      this.$bvModal.show("modal-guest");
    }
  }
};
</script>

<style>
.old-total {
  color: #acacac;
}
.list {
  overflow: auto;
  min-width: 406px;
}
</style>