<template>
  <div class="home">
    <header-app v-bind:infoUser="user" />
    <nav-row />
    <tab-day />
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderApp from "@/components/HeaderApp.vue";
import NavRow from "@/components/NavRow.vue";
import TabDay from "@/components/TabDay.vue";

export default {
  name: "home",
  data: function() {
    return {
      user: {
        name: "Name",
        password: "Center"
      }
    };
  },
  components: {
    HeaderApp,
    NavRow,
    TabDay
  }
};
</script>
