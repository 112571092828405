<template>
  <b-modal
    id="modal-user"
    title="Personal Information"
    @show="loadData()"
    hide-footer
  >
    <b-form v-on:submit.prevent="onSubmit">
      <!-- All entries from data -->
      <b-row class="my-1" v-for="entry in entries" :key="entry.id">
        <b-col sm="3">
          <label :for="entry">{{ entry.name }}:</label>
        </b-col>
        <b-col sm="9">
          <b-form-input
            v-model="newInfo[entry.id]"
            :id="entry.id"
            :type="entry.type"
            :placeholder="entry.placeholder"
          ></b-form-input>
        </b-col>
      </b-row>

      <!-- Phone provider -->
      <b-row class="my-1">
        <b-col sm="3">
          <label>Provider:</label>
        </b-col>
        <b-col sm="9">
          <b-form-select
            v-model="newInfo.provider"
            :options="optionsSMS"
            size="sm"
            :disabled="!validPhone"
          ></b-form-select>
        </b-col>
      </b-row>

      <!-- Role -->
      <b-row class="my-1 mb-4" v-if="(infoUser.status & 8) > 0">
        <b-col sm="3">
          <label>Role:</label>
        </b-col>
        <b-col sm="9">
          <b-form-select
            v-model="newInfo.status"
            :options="optionsRoles"
            size="sm"
          ></b-form-select>
        </b-col>
      </b-row>

      <div
        class="text-center mb-5"
        v-if="infoUser.initials != newInfo.initials"
      >
        <h6>Send Invitation</h6>
        <b-button-group>
          <b-button
            @click="sendInvitation('email')"
            variant="info"
            :disabled="validEmail"
            >Email</b-button
          >
          <b-button
            @click="sendInvitation('text')"
            variant="info"
            :disabled="!validProvider"
            >Text Message</b-button
          >
        </b-button-group>
      </div>

      <!-- Buttons -->
      <div class="text-center">
        <b-button type="submit" variant="primary">Submit</b-button>
        <b-button class="ml-1" @click="onCancel" variant="warning"
          >Cancel</b-button
        >
        <b-button
          v-if="newInfo.id > 0"
          class="ml-1"
          @click="onDelete()"
          variant="danger"
          >Delete</b-button
        >
      </div>
    </b-form>
  </b-modal>
</template>

<script>
export default {
  props: { user: Object, updateRootUser: { default: true, type: Boolean } },
  data: function () {
    return {
      newInfo: {},
      entries: [
        { name: "Name", type: "text", id: "name" },
        { name: "Initials", type: "text", id: "initials" },
        {
          name: "Diet",
          type: "text",
          id: "diet",
          placeholder: "Diet code e.g. A, #2, MJS,...",
        },
        {
          name: "Description",
          type: "text",
          id: "description",
          placeholder: "Diet Description",
        },
        { name: "Email", type: "email", id: "email" },
        { name: "Birthday", type: "date", id: "birthday" },
        { name: "Phone", type: "number", id: "phone" },
      ],
      optionsSMS: [
        { value: "", text: "No Messages   " },
        { value: "txt.att.net", text: "AT&T" },
        { value: "messaging.sprintpcs.com", text: "Sprint" },
        { value: "tmomail.net", text: "T-Mobile" },
        { value: "email.uscc.net", text: "U.S. Cellular" },
        { value: "vtext.com", text: "Verizon Wireless" },
        { value: "sms.alltelwireless.com", text: "Alltel" },
        { value: "sms.myboostmobile.com", text: "Boost Mobile" },
        { value: "mms.cricketwireless.net", text: "Cricket Wireless" },
        { value: "mymetropcs.com", text: "MetroPCS" },
        { value: "msg.fi.google.com", text: "Google Fi" },
        { value: "text.republicwireless.com	", text: "Republic Wireless" },
        { value: "vmobl.com", text: "Virgin Mobile" },
      ],
      optionsRoles: [
        { value: "1", text: "Resident" },
        { value: "3", text: "Resident - May edit Notes for Admin." },
        { value: "7", text: "Priest - May Edit Ordo & Notes for Admin." },
        { value: "15", text: "Director - May Edit Notes/Ordo/Users" },
        { value: "0", text: "Admin. - See Ordo, Cal. & Totals only" },
      ],
    };
  },
  computed: {
    infoUser() {
      return this.$store.state.userInfo;
    },
    validEmail() {
      // eslint-disable-next-line no-useless-escape
      var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return !re.test(this.newInfo.email);
    },
    validPhone() {
      return this.newInfo.phone && this.newInfo.phone.length > 9;
    },
    validProvider() {
      return this.newInfo.provider != "";
    },
  },
  watch: {
    validPhone: function () {
      if (!this.validPhone) {
        this.newInfo.provider = "";
      }
    },
  },
  methods: {
    onSubmit() {
      var url = "updateUserDiets.php";
      this.$http.post(url, this.newInfo, { emulateJSON: true }).then(
        () => {
          if (this.updateRootUser) {
            this.$store.commit("updateUserInfo", this.newInfo);
          }
          this.$bvToast.toast(`Updated`, {
            title: "User Information",
            variant: "info",
            toaster: "b-toaster-top-center",
            autoHideDelay: 2000,
            appendToast: false,
          });
          this.$emit("user-edited");
        },
        // eslint-disable-next-line no-unused-vars
        (err) => {}
      );
      this.$bvModal.hide("modal-user");
    },
    onCancel: function () {
      this.$bvModal.hide("modal-user");
    },
    onDelete() {
      let answer = "";
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure you want to permanetly delete " +
            this.newInfo.name +
            "?",
          {
            title: "Dletete Confirmation",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Yes",
            cancelTitle: "Cancel",
            cancelVariant: "warning",
            headerClass: "p-2 border-bottom-0",
            footerClass: "p-2 border-top-0",
            centered: true,
          }
        )
        .then((value) => {
          answer = value;
          if (answer) {
            var url = "deleteUser.php";
            var data = { id: this.newInfo.id };
            this.$http.post(url, data, { emulateJSON: true }).then(() => {
              this.$emit("user-edited");
              this.$bvModal.hide("modal-user");
            });
          }
        })
        .catch(() => {
          // An error occurred
        });
    },
    loadData: function () {
      const entries = Object.entries(this.user);
      for (const [k, v] of entries) {
        //this.newInfo[k] = v;
        this.$set(this.newInfo, k, v);
      }
      if (this.newInfo.id == 0) {
        this.newInfo.password = this.infoUser.password;
      }
    },
    sendInvitation(type) {
      var url = "CentersAppInvitations.php";
      var data = { user: this.newInfo, type: type };
      this.$http.post(url, data, { emulateJSON: true }).then((res) => {
        this.$bvToast.toast(res.body, {
          title: `User Info`,
          toaster: "b-toaster-top-center",
          variant: "success",
          solid: true,
        });
      });
    },
  },
};
</script>

<style>
</style>