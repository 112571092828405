import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import VueResource from 'vue-resource'
Vue.use(VueResource);

import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faUser, faKey, faCaretRight, faCaretLeft, faUndo, faThumbsUp, faExclamation, faSyncAlt, faSlash, faSpinner,
    faFileExport, faFileImport, faCog, faMinusCircle, faMinus, faCalendarTimes,
    faHamburger, faGlassCheers,faBirthdayCake,faArrowRight,faArrowLeft,
    faArrowCircleRight,faArrowCircleLeft,faArrowsAltH,faCampground,faFire,
    faHiking,faTree,faBeer,faWineBottle,faClock,faGhost,faSnowman,faCouch,faFilm,
    faCross, faCheck, faEye, faEyeSlash,
    faBaby, faBalanceScale, faBaseballBall, faBasketballBall, faBed, faBell, faBiking,
    faBinoculars, faBlind, faBolt, faBook, faBookReader, faBoxes, faBug, faBroom, faBus,
    faCalendar, faCandyCane, faCar, faCat, faCheese, faChess, faChurch, faCocktail,
    faCoffee, faDice, faDna, faDog, faDollarSign, faDove, faDrum, faEnvelope,
    faFingerprint, faFootballBall, faFutbol, faIceCream, faMapMarked, faMask, faMusic,
    faPizzaSlice, faPlane, faPray, faQuestion, faSnowboarding, faSmoking, faSocks, faSubway,
    faTableTennis, faPuzzlePiece, faSwimmer, faStopwatch, faGifts, faGift, faPlaneDeparture,
    faPlaneArrival} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)

library.add(faUser, faKey, faCaretRight, faCaretLeft, faUndo, faThumbsUp, faExclamation, faSyncAlt, faSlash,
    faSpinner, faFileExport, faFileImport, faCog, faMinusCircle,faMinus, faCalendarTimes,
    faHamburger, faGlassCheers,faBirthdayCake,faArrowRight,faArrowLeft,
    faArrowCircleRight,faArrowCircleLeft,faArrowsAltH,faCampground,faFire,
    faHiking,faTree,faBeer,faWineBottle,faClock,faGhost,faSnowman,faCouch,faFilm,
    faCross, faCheck, faEye, faEyeSlash,
    faBaby, faBalanceScale, faBaseballBall, faBasketballBall, faBed, faBell, faBiking,
    faBinoculars, faBlind, faBolt, faBook, faBookReader, faBoxes, faBug, faBroom, faBus,
    faCalendar, faCandyCane, faCar, faCat, faCheese, faChess, faChurch, faCocktail,
    faCoffee, faDice, faDna, faDog, faDollarSign, faDove, faDrum, faEnvelope,
    faFingerprint, faFootballBall, faFutbol, faIceCream, faMapMarked, faMask, faMusic,
    faPizzaSlice, faPlane, faPray, faQuestion, faSnowboarding, faSmoking, faSocks, faSubway,
    faTableTennis, faPuzzlePiece, faSwimmer, faStopwatch, faGifts, faGift, faPlaneDeparture,
    faPlaneArrival
    )

import Datetime from 'vue-datetime'

// You need a specific loader for CSS files
import 'vue-datetime/dist/vue-datetime.css'

import store from './store'
import router from './router'
Vue.use(Datetime)

Vue.config.productionTip = false;

new Vue({
    store,
    router,
    render: h => h(App)
}).$mount('#app')
