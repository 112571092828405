import Vue from "vue";
import Vuex from "vuex";
import moment from "moment";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isUserLogin: false,
    userInfo: {
      name: "",
      password: ""
    },
    currentDay: moment().toISOString(true),
    currentMonth: moment().format("YYYY-MM"),
    currentWeek: moment()
      .startOf("week")
      .format("YYYY-MM-DD"),
    reloadData: false,
    isSmallWindow: false,
    settings: {
      Breakfast: [],
      Lunch: [],
      Dinner: [],
      MealOptions: [],
      db: {
        name: "dbMil",
        centerInitials: "Layton",
        placeInitials: "Layton",
        actsDayIncluded: "'med1','rt','CMS'",
        actsCalResidents: "'Mass','med1','rt','med','CMS'",
        actsCalAdmin: "'Mass','med1','rt','CMS'"
      },
      startWeekDay: 0,
      labelE: "Early",
      dateActEditing: ""
    }
  },
  getters: {
    currentWeek: state => {
      return moment(state.currentWeek)
        .startOf("week")
        .add(state.settings.startWeekDay, "d")
        .format("YYYY-MM-DD");
    }
  },
  mutations: {
    updateDateActEditing(state, d) {
      state.dateActEditing = d;
    },
    isSmallWindow(state, b) {
      state.isSmallWindow = b;
    },
    loginState(state, b) {
      state.isUserLogin = b;
    },
    loadUserInfo(state, userInfo) {
      state.userInfo = userInfo;
    },
    updateSettings(state, st, o) {
      // it is not used
      state.settings[st] = o;
    },
    loadSettings(state, o) {
      state.settings.db.name = o.db_name;
      state.settings.Breakfast = JSON.parse(o.Breakfast);
      state.settings.Lunch = JSON.parse(o.Lunch);
      state.settings.Dinner = JSON.parse(o.Dinner);
      state.settings.db.centerInitials = o.db_centerInitials;
      state.settings.db.placeInitials = o.db_placeInitials;
      state.settings.db.actsDayIncluded = o.db_actsDayIncluded;
      state.settings.db.actsCalResidents = o.db_actsCalResidents;
      state.settings.db.actsCalAdmin = o.db_actsCalAdmin;
      state.settings.startWeekDay = o.startWeekDay;
      state.settings.labelE = o.labelE || "Early";
      state.settings.MealOptions = o.MealOptions;
    },
    loadMealOptions(state,o) {
      state.settings.MealOptions = o;
    },
    updateSettingsDB (state,o) {
      state.settings.db.actsDayIncluded = o.db_actsDayIncluded;
      state.settings.db.actsCalResidents = o.db_actsCalResidents;
      state.settings.db.actsCalAdmin = o.db_actsCalAdmin;
    },
    updateStartWeekDay(state,o) {
      state.settings.startWeekDay = o.startWeekDay;
    },
    updateCurrentDay(state, st) {
      state.currentDay = st;
    },
    updateCurrentDayToday(state) {
      state.currentDay = moment().toISOString(true);
    },
    updateCurrentMonth(state, st) {
      state.currentMonth = st;
    },
    updateCurrentWeek(state, st) {
      state.currentWeek = st;
    },
    reloadData(state, b) {
      state.reloadData = b;
    },
    reloadCurrentDay(state) {
      var temp = moment(state.currentDay).add(1, "s");
      state.currentDay = temp.toISOString(true);
    },
    updateUserInfo(state, newinfo) {
      const entries = Object.entries(newinfo);
      for (const [k, v] of entries) {
        //this.newInfo[k] = v;
        Vue.set(state.userInfo, k, v);
      }
    }
  },
  actions: {},
  modules: {}
});
