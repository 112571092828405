<template>
  <div id="app">
    <b-container style="max-width: 860px;">
      <!-- Navigation Bar -->
      <b-navbar type="dark" id="navbar" sticky>
        <b-navbar-toggle target="nav-text-collapse"></b-navbar-toggle>
        <b-collapse id="nav-text-collapse" is-nav>
          <!-- Tabs -->
          <b-navbar-nav
            style="width: 100%;"
            align="center"
            fill
            v-bind:class="{ 'small-window': isSmallDisplay }"
          >
            <b-nav-item v-if="infoUser.status > 0" to="/" replace>Day</b-nav-item>
            <b-nav-item
              v-if="infoUser.status > 0  && infoUser.initials != 'Guest'"
              to="/week"
              replace
            >Week</b-nav-item>
            <b-nav-item to="/ordo" replace>Ordo</b-nav-item>
            <b-nav-item to="/calendarv2" replace>Calendar</b-nav-item>
            <b-nav-item to="/totals" replace>Totals</b-nav-item>
          </b-navbar-nav>

          <!-- settings icon in the rigth -->
          <b-navbar-nav class="ml-auto" v-if="(infoUser.status & 8) > 0">
            <b-nav-item-dropdown no-caret right>
              <template v-slot:button-content>
                <font-awesome-icon class="mr-1" icon="cog" :style="{ color: 'new-color' }" />
              </template>
              <b-dropdown-item to="/settings-list-residents" replace>List of Residents</b-dropdown-item>
              <b-dropdown-item to="/settings-options-meals">Options for Meals</b-dropdown-item>
              <b-dropdown-item to="/settings-reminders">Reminders & Week Start Day</b-dropdown-item>
              <b-dropdown-item to="/settings-comments">Recurrent Notes to Admin</b-dropdown-item>
              <b-dropdown-item to="/settings-advance">Advance Settings</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
      <!-- login window or the app -->
      <login v-if="!isUserLogin" v-bind:isUserLogin="!isUserLogin" />
      <router-view v-else />
    </b-container>
  </div>
</template>

<script>
import Login from "./components/Login.vue";


export default {
  components: {
    Login
  },
  computed: {
    isUserLogin() {
      return this.$store.state.isUserLogin;
    },
    infoUser() {
      return this.$store.state.userInfo;
    },
    isSmallDisplay() {
      return this.$store.state.isSmallWindow;
    }
  },
  data: function() {
    return {
      isHidden: false,
      hidden: ""
    };
  },
  mounted() {
    this.sessionCheck();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();

    var hidden, visibilityChange;
    if (typeof document.hidden !== "undefined") {
      // Opera 12.10 and Firefox 18 and later support
      hidden = "hidden";
      visibilityChange = "visibilitychange";
    } else if (typeof document.msHidden !== "undefined") {
      hidden = "msHidden";
      visibilityChange = "msvisibilitychange";
    } else if (typeof document.webkitHidden !== "undefined") {
      hidden = "webkitHidden";
      visibilityChange = "webkitvisibilitychange";
    }
    this.hidden = hidden;

    // If the page is hidden, pause the video;
    // if the page is shown, play the video

    // Warn if the browser doesn't support addEventListener or the Page Visibility API
    if (
      typeof document.addEventListener === "undefined" ||
      hidden === undefined
      // eslint-disable-next-line no-empty
    ) {
    } else {
      // Handle page visibility change
      document.addEventListener(
        visibilityChange,
        this.handleVisibilityChange,
        false
      );
    }
  },
  methods: {
    sessionCheck() {
      var url = "checkSession-meals.php";
      var data = {};
      this.$http.post(url, data, { emulateJSON: true }).then(
        res => {
          if (res.body["result"]) {
            this.$store.commit("loginState", true);
            var user = res.body;
            this.$store.commit("updateUserInfo", {
              id: user.id,
              name: user.Name,
              initials: user.UserName,
              password: user.Password,
              diet: user.Diet,
              email: user.Email,
              birthday: user.Birthday,
              phone: user.Phone,
              provider: user.Provider,
              status: user.Status
            });
            this.$store.commit("loadSettings", res.body["settings"]);
            if (this.infoUser.status == 0) {
              // eslint-disable-next-line no-unused-vars
              this.$router.push("totals").catch(err => {});
            }
          } else {
            this.noLogin = true;
          }
        },
        // eslint-disable-next-line no-unused-vars
        err => {}
      );
    },
    handleVisibilityChange() {
      // eslint-disable-next-line no-empty
      if (document[this.hidden]) {
      } else {
        this.$store.commit("reloadData", true);
      }
    },
    handleResize() {
      if (window.innerWidth < 600) {
        this.$store.commit("isSmallWindow", true);
      } else {
        this.$store.commit("isSmallWindow", false);
      }
    }
  }
};
</script>

<style lang="scss">

@import './assets/custom-styles.scss';


#navbar {
  background-color: $orange;
  a {
    opacity: 1;
  }
  a:hover {
    opacity: 1;
  }
}

.router-link-exact-active {
  
  font-weight: 600;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  font-size: 1.1em;
}



.small-window {
  font-size: 0.9em;
}

</style>
