<template>
  <div class="mb-3">
    <b-row align-v="center" class="text-center" no-gutters>
      <b-col cols="2">
        <b-button style="color: blue; background:none; border:none" @click="dateAdd(-1)">
          <font-awesome-icon icon="caret-left" :style="{ color: 'blue' }" size="3x" />
        </b-button>
      </b-col>
      <b-col v-bind:cols="isCurrentDayToday ? 8 : 7 ">
        <datetime
          v-model="dayISO"
          :week-start="7"
          :auto="true"
          :value-zone="valueZone"
          v-bind:input-class="isCurrentDayToday ? 'background-date-today' : 'background-date'"
          format="cccc, LLL. d"
        ></datetime>
      </b-col>
      <b-col cols="1" v-if="!isCurrentDayToday">
        <b-button style="background:none; border:none" @click="goToToday()">
          <font-awesome-icon icon="undo" :style="{ color: 'blue' }" />
        </b-button>
      </b-col>
      <b-col cols="2">
        <b-button style="color: blue; background:none; border:none" @click="dateAdd(1)">
          <font-awesome-icon icon="caret-right" :style="{ color: 'blue' }" size="3x" />
        </b-button>
      </b-col>
    </b-row>
    <!-- Show Feastdays -->
    <b-row
      v-if="calendarDataFeasts.Celebration"
      style="color: blue"
      align-v="center"
      class="text-center"
    >
      <b-col cols="12">{{ calendarDataFeasts.Celebration }} [{{ calendarDataFeasts.Category }}]</b-col>
    </b-row>
    <!-- Show All Act from MyNetCal -->
    <div v-for="Act in calendarDataMyNetCal" v-bind:key="Act.StartTime">
      <b-badge variant="warning">
        <span style="font-weight: 400" class="mr-2">{{getMyNetActTime(Act)}}</span>
        {{ getMyNetActFormat(Act) }}
      </b-badge>
    </div>

    <!-- Show Especial Activities -->
    <div v-for="Act in calendarDataAct" v-bind:key="Act.id">
      <b-badge variant="warning">
        <font-awesome-icon v-if="Act.Icon!='' && Act.Icon!='arrow-right'" :icon="Act.Icon" />
        {{ Act.Act }}
        <font-awesome-icon v-if="Act.Icon=='arrow-right'" :icon="Act.Icon" />
      </b-badge>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {},
  data: function() {
    return {
      calendarDataFeasts: {},
      calendarDataMyNetCal: [],
      calendarDataAct: []
    };
  },
  computed: {
    dayISO: {
      get() {
        return this.$store.state.currentDay;
      },
      set(value) {
        this.$store.commit("updateCurrentDay", value);
      }
    },
    isCurrentDayToday: function() {
      return moment(this.dayISO).isSame(moment(), "day");
    },
    valueZone: function() {
      return "utc" + moment().format("Z");
    },
    centerInitials: function() {
      return this.$store.state.settings.db.centerInitials;
    }
  },
  watch: {
    dayISO: function() {
      this.getActs();
    }
  },

  mounted() {
    this.getActs();
  },
  methods: {
    dateAdd: function(num) {
      var d = moment(this.dayISO);
      d.add(num, "d");
      this.dayISO = d.toISOString(true);
    },
    goToToday: function() {
      this.dayISO = moment().toISOString(true);
    },
    getActs() {
      this.calendarDataFeasts = {};
      this.calendarDataMyNetCal = [];
      this.calendarDataAct = [];
      let url = "getCalendarAll-v2.php";
      let starts = moment(this.dayISO).format("YYYY-MM-DD");
      let ends = moment(starts)
        .add(1, "d")
        .format("YYYY-MM-DD");
      let data = { starts: starts, ends: ends, view: "day" };
      let day = moment(starts).date();
      this.$http.post(url, data, { emulateJSON: true }).then(
        res => {
          this.calendarDataFeasts = res.data.Feasts[day]
            ? res.data.Feasts[day][0]
            : {};
          this.calendarDataMyNetCal = res.data.allPriestEvents[day] || [];
          this.calendarDataAct = res.data.Act[day] || [];
        },
        () => {}
      );
    },
    getMyNetActFormat(act) {
      let st = act.ActInitials + " " + act.AudInitials;
      st = st
        .replace("rt", "d/r")
        .replace("med1", "d/r")
        .replace(" sm", "")
        .replace("sr ", "");
      if (act.PlaInitials != this.centerInitials) {
        st += " @ " + act.PlaInitials;
      }
      return st;
    },
    getMyNetActTime(act) {
      let st = moment(act.StartTime, "HH:mm").format("h:mm a");
      return st;
    }
  }
};
</script>

<style>
.background-date {
  background-color: #d0f0ff;
  border-radius: 5px;
  border: none;
  width: 100%;
  text-align: center;
}

.background-date-today {
  background-color: #f0d0ff;
  border-radius: 5px;
  border: none;
  width: 100%;
  text-align: center;
}
</style>