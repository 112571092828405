<template>
  <div class="mb-3">
    <user-info v-bind:user="infoUser"/>
    <b-row class="row mt-2">
      <b-col cols="auto" class="mr-auto">
        <h2 class="mb-0 pb-0">{{ infoUser.password }}<span style="color: gray; font-size: 12px">v5.1</span></h2>
        
      </b-col>
      <b-col cols="auto">
        
        <font-awesome-icon icon="user" size="2x" />
        <b-button-group size="sm" class="ml-2 pb-3">
          <b-button size="sm" @click="$bvModal.show('modal-user')" v-bind:disabled="infoUser.status==0">{{ infoUser.initials }}</b-button>
          <b-button variant="light" size="sm" v-on:click="logout">Logout</b-button>
        </b-button-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import UserInfo from "./UserInfo.vue";

export default {
  props: {},
  computed: {
    infoUser() {
      return this.$store.state.userInfo;
    }
  },
  components: {
    UserInfo
  },
  methods: {
    logout: function() {
      this.$store.commit("loginState", false);
    }
  }
};
</script>

<style scoped>
</style>