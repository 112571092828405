<template>
  <!-- Modal for the login input -->
  <b-modal
    size="sm"
    v-model="isUserLogin"
    id="modal-login"
    title="Login"
    hide-footer
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    @show="loadData()"
  >
    <b-form inline>
      <b-input-group class="mb-1">
        <template v-slot:prepend>
          <b-input-group-text>
            <font-awesome-icon icon="user" :style="{ color: 'blue' }" />
          </b-input-group-text>
        </template>
        <b-form-input id="user" name="user" v-model="user" placeholder="User" fixed-width />
      </b-input-group>
      <b-input-group>
        <template v-slot:prepend>
          <b-input-group-text>
            <font-awesome-icon icon="key" :style="{ color: 'blue' }" />
          </b-input-group-text>
        </template>
        <b-form-input
          id="password"
          v-model="password"
          name="password"
          placeholder="Password"
          fixed-width
        />
        <b-form-invalid-feedback
          :state="validLogAttempt"
        >User and/or Password don't Match. Please try again.</b-form-invalid-feedback>
        <b-form-valid-feedback :state="validLogAttempt"></b-form-valid-feedback>
      </b-input-group>
    </b-form>
    <b-button class="mt-3" variant="info float-right" @click="loginCheck(user,password)">Ok</b-button>
  </b-modal>
</template>

<script>
export default {
  props: {
    isUserLogin: Boolean
  },
  data: function() {
    return {
      firstLoginAttempt: true,
      user: "",
      password: ""
    };
  },
  computed: {
    validLogAttempt: function() {
      return !(this.user.length == 0 && !this.firstLoginAttempt);
    },
    infoUser() {
      return this.$store.state.userInfo;
    }
  },
  methods: {
    loadData() {
      this.user = "";
      this.password = "";
      this.firstLoginAttempt = true;
    },
    loginCheck(user, password) {
      var url = "startSession-meals.php";
      var data = { username: user, password: password };
      this.$http.post(url, data, { emulateJSON: true }).then(
        res => {
          if (res.body["result"]) {
            this.firstLoginAttempt = true;
            this.$store.commit("loginState", true);
            var user = res.body;
            this.$store.commit("updateUserInfo", {
              id: user.id,
              name: user.Name,
              initials: user.UserName,
              password: user.Password,
              diet: user.Diet,
              email: user.Email,
              birthday: user.Birthday,
              phone: user.Phone,
              provider: user.Provider,
              status: user.Status
            });
            this.$store.commit("loadSettings", res.body["settings"]);
            if (this.infoUser.status == 0) {
              this.$router.push("totals").catch(err => {});
            }
          } else {
            this.firstLoginAttempt = false;
            this.user = "";
            this.password = "";
          }
        },
        err => {}
      );
    }
  }
};
</script>

<style scoped>
</style>