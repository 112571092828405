/*jshint -W024 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/ordo',
    name: 'ordo',
    component: () => import(/* webpackChunkName: "ordo" */ '../views/Ordo.vue')
  },
  {
    path: '/totals',
    name: 'totals',
    component: () => import(/* webpackChunkName: "totals" */ '../views/Totals.vue')
  },
  {
    path: '/calendarv2',
    name: 'calendarv2',
    component: () => import(/* webpackChunkName: "calendar" */ '../views/Calendarv2.vue')
  },
  {
    path: '/week',
    name: 'week',
    component: () => import(/* webpackChunkName: "week" */ '../views/Week.vue')
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue')
  },
  {
    path: '/settings-list-residents',
    name: 'settings-list-residents',
    component: () => import(/* webpackChunkName: "residents" */ '../views/Settings/ListResidents.vue')
  },
  {
    path: '/settings-options-meals',
    name: 'settings-options-meals',
    component: () => import(/* webpackChunkName: "options" */ '../views/Settings/OptionsMealsCustom.vue')
  },
  {
    path: '/settings-reminders',
    name: 'settings-reminders',
    component: () => import(/* webpackChunkName: "reminders" */ '../views/Settings/Reminders.vue')
  },
  {
    path: '/settings-comments',
    name: 'settings-comments',
    component: () => import(/* webpackChunkName: "comments" */ '../views/Settings/Comments.vue')
  },
  {
    path: '/settings-advance',
    name: 'settings-advance',
    component: () => import(/* webpackChunkName: "advance" */ '../views/Settings/Advance.vue')
  }

]

const router = new VueRouter({
 
  base: process.env.BASE_URL,
  routes
})

export default router
