<template>
  <b-row
    align-v="end"
    no-gutters
    v-bind:class="{ 'current-user-row': resident.CurrentUser=='1' }"
    class="text-center row-meals mt-1"
  >
    <!-- Username  Resident -->
    <b-col
      cols="2"
      v-if="resident.Guest=='0'"
      v-bind:class="{ 'current-user-name': resident.CurrentUser=='1' }"
    >
      <div class="mr-1">{{ resident.UserName }}</div>
    </b-col>
    <!-- Usesname Guest with a button -->
    <b-col cols="2" v-else v-bind:class="{ 'current-user-name': resident.CurrentUser=='1' }">
      <b-button
        class="mr-1"
        variant="outline-primary"
        style="width: 100%"
        @click="$emit('editGuest',resident)"
      >{{ resident.UserName }}</b-button>
    </b-col>

    <!-- Breakfast buttons -->
    <b-col cols="auto">
      <div
        v-if="resident.CurrentUser=='1'"
        v-bind:class="{ 'old-total': timeday.isAfterBreakfast }"
      >Breakfast</div>
      <b-button-group
        size="sm"
        class="mr-1"
        v-bind:class="{ 'current-user-buttons': resident.CurrentUser=='1' && !timeday.isAfterBreakfast }"
      >
        <meals-button
          v-for="item in settings.MealOptions['1']"
          v-bind:item="item"
          v-bind:key="item.type"
          meal="Breakfast"
          v-bind:resident="resident"
          v-on:listChange="$emit('listChange',$event)"
        />
      </b-button-group>
    </b-col>

    <!-- Lunch Buttons -->
    <b-col cols="auto">
      <div
        v-if="resident.CurrentUser=='1'"
        v-bind:class="{ 'old-total': timeday.isAfterLunch }"
      >Lunch</div>

      <b-button-group
        size="sm"
        class="mr-1"
        v-bind:class="{ 'current-user-buttons': resident.CurrentUser=='1' && !timeday.isAfterLunch }"
      >
        <meals-button
          v-for="item in settings.MealOptions['2']"
          v-bind:item="item"
          v-bind:key="item.type"
          meal="Lunch"
          v-bind:resident="resident"
          v-on:listChange="$emit('listChange',$event)"
        />
      </b-button-group>
    </b-col>

    <!-- Dinner Buttons -->
    <b-col cols="auto" >
      <div
        v-if="resident.CurrentUser=='1'"
        v-bind:class="{ 'old-total': timeday.isAfterDinner }"
      >Dinner</div>

      <b-button-group
        class="mr-1"
        size="sm"
        v-bind:class="{ 'current-user-buttons': resident.CurrentUser=='1' && !timeday.isAfterDinner }"
      >
        <meals-button
          v-for="item in settings.MealOptions['3']"
          v-bind:item="item"
          v-bind:key="item.type"
          meal="Dinner"
          v-bind:resident="resident"
          v-on:listChange="$emit('listChange',$event)"
        />
      </b-button-group>
    </b-col>

    <!-- Icons -->
    <b-col cols="auto">
      <font-awesome-icon
        icon="thumbs-up"
        :style="{ color: 'blue' }"
        v-if="resident.Dinner != ''"
        size="xs"
      />
      <font-awesome-icon icon="exclamation" :style="{ color: 'red' }" v-else size="sm" />
    </b-col>
  </b-row>
</template>

<script>
import MealsButton from "./MealsButton.vue";

export default {
  props: {
    resident: Object,
    timeday: Object
  },

  computed: {
    settings() {
      return this.$store.state.settings;
    }
  },
  components: {
    MealsButton
  },
  methods: {}
};
</script>

<style>
.current-user-name {
  color: red;
  font-weight: bold;
}

.current-user-row {
  margin-bottom: 1.2em;
}

.current-user-buttons {
  border: 1px solid red;
  border-radius: 5px;
}
.old-total {
  color: #acacac;
}
</style>