<template>
  <b-modal id="modal-guest" title="Guest" @show="clearData()" hide-footer>
    <b-form v-on:submit.prevent="onSubmit">
      <h4>{{ currentDay.format("dddd, MMMM D") }}</h4>
      <b-form-group label="Name">
        <b-input v-model="newGuest.name" />
        <b-form-invalid-feedback :state="validName">Name can't be empty</b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Diet">
        <b-form-input v-model="newGuest.diet"></b-form-input>
      </b-form-group>
      <div class="text-center">
        <b-button type="submit" variant="primary">Save</b-button>
        <b-button class="ml-1" @click="onCancel" variant="warning">Cancel</b-button>
        <b-button class="ml-1" v-if="guest.id!=''" @click="onDelete" variant="danger">Delete</b-button>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import moment from "moment";

export default {
  props: ["guest"],
  data() {
    return {
      validName: true
    };
  },
  computed: {
    newGuest: function() {
      return {
        name: this.guest.name,
        diet: this.guest.diet,
        id: this.guest.id
      };
    },
    currentDay() {
      return moment(this.$store.state.currentDay);
    }
  },
  methods: {
    onSubmit() {
      if (this.newGuest.name.length > 0) {
        var url = "editGuest.php";
        var data = {
          name: this.newGuest.name,
          diet: this.newGuest.diet,
          id: this.newGuest.id,
          date: moment(this.currentDay).format("YYYY-MM-DD"),
          delete: "false"
        };
        this.$http.post(url, data, { emulateJSON: true }).then(
          res => {
            this.$emit("guest-updated");
          },
          err => {}
        );
        this.$bvModal.hide("modal-guest");
      } else {
        this.validName = false;
      }
    },
    onCancel() {
      this.$bvModal.hide("modal-guest");
    },
    onDelete() {
      var url = "editGuest.php";
      var data = {
        name: this.newGuest.name,
        diet: this.newGuest.diet,
        id: this.newGuest.id,
        date: moment(this.currentDay).format("YYYY-MM-DD"),
        delete: "true"
      };
      this.$http.post(url, data, { emulateJSON: true }).then(
        res => {
          this.$emit("guest-updated");
        },
        err => {}
      );
      this.$bvModal.hide("modal-guest");
    },
    clearData() {
      this.validName = true;
    }
  }
};
</script>

<style>
</style>